import * as React from "react";
import { 
  container,
  heading,
  navLinks,
  navLinkItem,
  navLinkText
} from "./layout.module.css";
import {
  graphql,
  Link,
  useStaticQuery
} from "gatsby"
import { StaticImage } from "gatsby-plugin-image";

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';

// styles
const pageStyles = {
  color: "#232129",
  padding: 96,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}
const headingAccentStyles = {
  color: "#663399",
}
const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}
const listStyles = {
  marginBottom: 96,
  paddingLeft: 0,
}
const listItemStyles = {
  fontWeight: 300,
  fontSize: 24,
  maxWidth: 560,
  marginBottom: 30,
}

const linkStyle = {
  color: "#8954A8",
  fontWeight: "bold",
  fontSize: 16,
  verticalAlign: "5%",
}

const docLinkStyle = {
  ...linkStyle,
  listStyleType: "none",
  marginBottom: 24,
}

const descriptionStyle = {
  color: "#232129",
  fontSize: 14,
  marginTop: 10,
  marginBottom: 0,
  lineHeight: 1.25,
}

const docLink = {
  text: "Documentation",
  url: "https://www.gatsbyjs.com/docs/",
  color: "#8954A8",
}

const badgeStyle = {
  color: "#fff",
  backgroundColor: "#088413",
  border: "1px solid #088413",
  fontSize: 11,
  fontWeight: "bold",
  letterSpacing: 1,
  borderRadius: 4,
  padding: "4px 6px",
  display: "inline-block",
  position: "relative",
  top: -2,
  marginLeft: 10,
  lineHeight: 1,
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;

const Layout = ({ pageTitle, children }) => {
  const data = useStaticQuery(query);
  let title = data.site.siteMetadata.title;
  let description = data.site.siteMetadata.description;
  let siteTitle = `${title} - ${description}`;
  let mottoLine = "Make Technology Your Competitive Advantage";

  return (
    <>
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          <Navbar.Toggle aria-controls="navbar-menu" />
          <Navbar.Brand as={Link} to="/">steve fox</Navbar.Brand>
          <Navbar.Collapse id="navbar-menu">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/" activeClassName="active">Home</Nav.Link>
            <Nav.Link as={Link} to="/blog" activeClassName="active">Blog</Nav.Link>
            <Nav.Link as={Link} to="/about" activeClassName="active">About</Nav.Link>
          </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    <Container className="p-3">
      <main>
        {children}
      </main>
    </Container>
  </>
  )
}

export default Layout
